import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src="/logo192.png" alt="Logo" />
        <a
          className="App-link"
          href="https://github.com/zehrl/lineup"
          target="_blank"
          rel="noopener noreferrer"
          style={{padding: "1em", margin: "1em 0"}}
        >
          Lineup by Groundscore LLC
        </a>
      </header>
    </div>
  );
}

export default App;
